import Link from "next/link";
import React, { Fragment } from "react";
import BlogCard, { BlogCardProps } from "../Cards/BlogCard/BlogCard";
import ContactButton from "./../Buttons/ContactButton/ContactButton";

export type BlogProps = {
  title?: string | null;
  blogCard?: BlogCardProps[] | null;
  background?: string | null;
  viewMore: string;
};

const Blog = ({ blogCard, viewMore, title }: BlogProps) => {
  return (
    <>
      <section id="blog-section" className="py-16 lg:py-20">
        <div className="container px-4 mx-auto">
          <div className="mb-16 max-w-3xl mx-auto text-center">
            <h1 className="mt-2 mb-4 text-4xl lg:text-5xl font-bold font-heading">
              {title}
            </h1>
          </div>
          <div className="flex flex-wrap -mx-4 mb-12">
            {blogCard?.slice(0, 3).map((res, index) => {
              return (
                <Fragment key={`blogCard-${index}`}>
                  <BlogCard
                    id={res.id}
                    title={res.title}
                    cover={res.cover}
                    contentPreview={res.contentPreview}
                    slug={res.slug}
                    tag={res.tag}
                    publishedAt={res.publishedAt}
                    viewMore={viewMore}
                  />
                </Fragment>
              );
            })}
          </div>
          <div className="text-center">
            <Link href="/blogs">
              <ContactButton text={viewMore} />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
