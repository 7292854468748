import React, { FC } from "react";
import NewLineBreak from "../../NewLineBreak/NewLineBreak";

type FaqButtonProps = {
  hidden: boolean;
  title: string;
  content: string;
  onClick: () => void;
};

const FaqButton = ({ hidden, title, content, onClick }: FaqButtonProps) => {
  return (
    <>
      <li
        className="px-6 py-8 bg-primary-50 rounded-lg hover:bg-primary-100 cursor-pointer"
        onClick={onClick}
      >
        <button className="w-full flex justify-between items-center text-left font-bold font-heading">
          <span className="text-2xl font-bold font-heading">{title}</span>
          <svg
            className="flex-shrink-0 w-4 h-4 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <p
          className={`${
            hidden && "hidden"
          } max-w-2xl mt-2 text-lg text-gray-500 leading-loose`}
        >
          <NewLineBreak text={content} />
        </p>
      </li>
    </>
  );
};

export default FaqButton;
