import React, { Fragment } from "react";
import { ProductName } from "../../utils/requests/rayongboard";
import ProductCard from "../Cards/Product/ProductCard";
import NewLineBreak from "../NewLineBreak/NewLineBreak";
import { BackgroundType } from "./../../utils/requests/rayongboard/index";

export type ProductProps = {
  title: string;
  description: string;
  productName: ProductName[];
  background: string;
};

const Products = ({
  title,
  description,
  productName,
  background,
}: ProductProps) => {

  return (
    <>
      <section id="products-section" className="relative my-16 lg:my-20">
        <div className="container px-4 mx-auto items-cemter">
          <div className="w-full lg:w-1/2 ml-auto mb-12">
            <div className="lg:max-w-md lg:ml-auto">
              <h1 className="mb-4 lg:mb-6 text-4xl md:text-5xl mt-3 font-bold font-heading">
                {title}
              </h1>
              <p className="mb-8 md:text-lg text-gray-500 leading-loose">
                <NewLineBreak text={description} />
              </p>
              <>
                {productName.map((res, index) => (
                  <Fragment key={`product-card-${index}`}>
                    <ProductCard title={res.title} />
                  </Fragment>
                ))}
              </>
            </div>
          </div>
        </div>
        <>
          <div
            className="h-96 lg:h-auto lg:absolute top-0 left-0 bottom-0 lg:w-1/2 bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(${process.env.NEXT_PUBLIC_API_ENDPOINT}${background})`,
            }}
          />
        </>
      </section>
    </>
  );
};

export default Products;
