import React from "react";
import { CertificateType } from "../../utils/requests/rayongboard";
import CertificateCard from "../Cards/CertificateCard/CertificateCard";

export type GuaranteeProps = {
  category?: string | null;
  topic?: string | null;
  description?: string | null;
  certificate: CertificateType[];
};

const Guarantee = ({
  category,
  topic,
  description,
  certificate,
}: GuaranteeProps) => {
  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        <div className="max-w-3xl mx-auto mb-12 lg:mb-16 text-center">
          <span className="text-sm text-gray-200 uppercase">{category}</span>
          <h1 className="mt-2 mb-4 text-4xl lg:text-5xl font-bold font-heading">
            {topic}
          </h1>
          <p className="text-lg text-gray-500 leading-loose">{description}</p>
        </div>
        <div className="flex flex-wrap -m-4 justify-center">
          {certificate?.map((res, index) => {
            return (
              <CertificateCard
                logo={res.logo.data?.attributes?.url}
                name={res.name}
                description={res.description}
                key={`certificate-card-${index}`}
                image={res.certificateImage.data?.attributes?.url}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Guarantee;
