import React from "react";

type Props = {
  text?: string | null;
};

const ContactButton = ({ text }: Props) => {
  return (
    <>
      <button className="inline-block bg-primary-50 hover:bg-primary-100 text-black w-auto py-3 px-6 rounded-lg transition duration-200 font-bold ">
        {text}
      </button>
    </>
  );
};

export default ContactButton;
