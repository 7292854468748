import Image from "next/image";
import React, { useState } from "react";
import ModalImage from "../../ModalImage/ModalImage";

export type CertificateProps = {
  logo?: string;
  name?: string;
  description?: string;
  image?: string;
};

const CertificateCard = ({
  logo,
  name,
  description,
  image,
}: CertificateProps) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const handleClickModal = () => {
    setIsActiveModal(!isActiveModal);
  };

  return (
    <>
      <div className="w-full md:w-1/2 py-3 lg:py-2 px-12 md:px-6 lg:px-2 lg:w-1/5">
        <div className="flex items-center justify-center bg-gray-50 py-12 rounded-lg">
          <div className="text-center">
            <Image
              className="w-20 h-20 mx-auto rounded-full mb-6 cursor-pointer"
              src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${logo}`}
              alt={`${name}-logo`}
              width={80}
              height={80}
              onClick={() => handleClickModal()}
            />
            <h2 className="text-2xl font-bold font-heading">{name}</h2>
            <p className="text-lg text-gray-500 px-2">{description}</p>
          </div>
        </div>
      </div>
      {isActiveModal && (
        <ModalImage
          img={image}
          logo={logo}
          name={name}
          onClose={() => handleClickModal()}
        />
      )}
    </>
  );
};

export default CertificateCard;
