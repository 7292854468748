import React, { Suspense } from "react";
import Blog, { BlogProps } from "../Blog/Blog";
import Faq, { FaqProps } from "../FAQ/Faq";
import Header from "../Header/Header";
import Trusted, { TrustedProps } from "../Trusted/Trusted";
import Products, { ProductProps } from "../Products/Products";
import ContactButton from "../Buttons/ContactButton/ContactButton";
import SloganCard from "../Cards/Slogan/SloganCard";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  FaqType,
  PartnerType,
  SloganType,
} from "../../utils/requests/rayongboard";
import NewLineBreak from "../NewLineBreak/NewLineBreak";
import Guarantee, { GuaranteeProps } from "../Guarantee/Guarantee";

export type HeaderProps = {
  companyName: string;
  tagline: string;
  background: string | null;
  slogan: SloganType[];
  buttonName: string;
};

type LandingProps = {
  header: HeaderProps | null;
  guarantee?: GuaranteeProps | null;
  trusted?: TrustedProps | null;
  product?: ProductProps | null;
  blog?: BlogProps | null;
  faq?: FaqProps | null;
};

const Landing = ({
  header,
  guarantee,
  faq,
  trusted,
  product,
  blog,
}: LandingProps) => {
  return (
    <>
      <div>
        <Header
          backgroundUrl={
            header?.background
              ? `${process.env.NEXT_PUBLIC_API_ENDPOINT}${header.background}`
              : `/images/default-001.webp`
          }
        >
          <div className="max-w-4xl mx-auto text-center mt-20 mb-24">
            <span className="text-lg text-gray-200">
              {header?.companyName
                ? header?.companyName
                : "Rayong Panel CO., Ltd. & Rayong MDF Co. ,Ltd."}
            </span>
            <h1 className="mb-8 mt-2 text-5xl lg:text-6xl text-white font-bold lg:font-black font-heading ">
              {header?.tagline ? (
                <NewLineBreak text={header.tagline} />
              ) : (
                <NewLineBreak text={"We are the best manufacturer of Board"} />
              )}
            </h1>
            <a
              href="mailto:info@rayong-mdf.com"
              target="_blank"
              rel="noreferrer"
            >
              {header?.buttonName && (
                <ContactButton text={header?.buttonName} />
              )}
            </a>
          </div>
          <div className="flex flex-wrap flex-col md:flex-row justify-center  align-top">
            {header?.slogan.map((res, index) => {
              return <SloganCard slogan={res} key={`slogan-card-${index}`} />;
            })}
          </div>
        </Header>
      </div>
      <div>
        {guarantee && (
          <Guarantee
            category={guarantee.category}
            topic={guarantee.topic}
            description={guarantee.description}
            certificate={guarantee.certificate}
          />
        )}
      </div>
      <div>
        {trusted && (
          <Trusted
            title={trusted.title}
            description={trusted.description}
            partner={trusted.partner}
          />
        )}
      </div>
      <div>
        {product && (
          <Products
            title={product.title}
            description={product.description}
            productName={product.productName}
            background={product.background}
          />
        )}
      </div>
      <div>
        {blog && blog.blogCard?.length && (
          <Blog
            blogCard={blog.blogCard}
            viewMore={blog.viewMore}
            title={blog.title}
          />
        )}
      </div>
      <div>
        {faq && (
          <Faq title={faq.title} description={faq.description} faq={faq.faq} />
        )}
      </div>
    </>
  );
};

export default Landing;
