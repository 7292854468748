import Head from "next/head";
import { useEffect } from "react";
import styles from "../styles/Home.module.css";
import axios from "axios";
import Landing, { HeaderProps } from "../components/Landing/Landing";
import Layout, { LogoProps } from "../components/Layout/Layout";
import { withTranslation } from "react-i18next";
import { get } from "../utils/requests/request";
import {
  getFaq,
  getGuarantee,
  getHeader,
  getLogo,
  getProduct,
  getSocial,
} from "../utils/requests/rayongboard";
import { FaqProps } from "../components/FAQ/Faq";
import { getTrusted } from "./../utils/requests/rayongboard/index";
import { TrustedProps } from "../components/Trusted/Trusted";
import { ProductProps } from "../components/Products/Products";
import {
  BlogType,
  getBlogConfig,
  getBlogs,
} from "../utils/requests/rayongboard/blogs";
import { BlogProps } from "../components/Blog/Blog";
import { SocialProps } from "../components/OtherAssets/Social/Social";
import { GuaranteeProps } from "../components/Guarantee/Guarantee";

export type HomeProps = {
  logo: LogoProps | null;
  header: HeaderProps | null;
  guarantee: GuaranteeProps | null;
  trusted: TrustedProps | null;
  product: ProductProps | null;
  blog: BlogProps | null;
  faq: FaqProps | null;
  social: SocialProps | null;
};

function Home({
  logo,
  header,
  guarantee,
  faq,
  trusted,
  product,
  blog,
  social,
}: HomeProps) {
  let keywords = "";
  product?.productName.forEach(function (res) {
    keywords = keywords + `, ${res.title}`;
  });

  return (
    <>
      <Head>
        <title>Rayong Board</title>
        <meta name="title" content={header?.companyName} />
        <meta name="description" content={header?.tagline} />
        <meta
          name="keywords"
          content={`${header?.companyName} , ${keywords}`}
        />

        <meta property="og:title" content={header?.companyName} />
        <meta property="og:description" content={header?.tagline} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_API_ENDPOINT}`}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${header?.background}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="687" />

        <meta property="twitter:title" content={header?.companyName} />
        <meta property="twitter:description" content={header?.tagline} />
        <meta
          property="twitter:url"
          content={`${process.env.NEXT_PUBLIC_API_ENDPOINT}`}
        />
        <meta property="twitter:card" content="" />
        <meta
          property="twitter:image"
          content={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${header?.background}`}
        />

        {/* <link rel="icon" href="" /> */}
      </Head>
      <Layout social={social} logo={logo}>
        <Landing
          header={header}
          guarantee={guarantee}
          faq={faq}
          trusted={trusted}
          product={product}
          blog={blog}
        />
      </Layout>
    </>
  );
}

// This function gets called at build time on server-side.
// It won't be called on client-side, so you can even do
// direct database queries.
export async function getStaticProps({ locale }: { locale: string }) {
  // Call an external API endpoint to get posts.
  // You can use any data fetching library

  let props: HomeProps = {
    logo: null,
    header: null,
    guarantee: null,
    trusted: null,
    product: null,
    blog: null,
    faq: null,
    social: null,
  };

  // getlogo
  await getLogo()
    .then((logoData) => {
      props.logo = {
        image:
          logoData.data.data.attributes?.image?.data?.attributes.url ||
          "/uploads/small_default_020_7ea52d2f69.webp",
        name: logoData.data.data.attributes?.image?.data?.attributes.name,
      };
    })
    .catch((err) => {
      console.error("getLogo error: ", err);
    });

  // ****************************************************************
  // ************************* getHeader ****************************
  // ****************************************************************

  await getHeader(locale)
    .then((headerData) => {
      props.header = {
        companyName: headerData.data.data.attributes.companyName,
        tagline: headerData.data.data.attributes.tagline,
        background:
          headerData.data.data.attributes?.background?.data?.attributes.url ||
          null,
        slogan: headerData.data.data.attributes.slogan,
        buttonName: headerData.data.data.attributes.buttonName,
      };
    })
    .catch((err) => {
      console.error("getHeader error: ", err);
    });

  // getGuarantee

  await getGuarantee(locale)
    .then((guaranteeData) => {
      props.guarantee = {
        category: guaranteeData.data.data.attributes.category,
        topic: guaranteeData.data.data.attributes.topic,
        description: guaranteeData.data.data.attributes.description,
        certificate: guaranteeData.data.data.attributes.certificate,
      };
    })
    .catch((err) => {
      console.error("getGuarantee error: ", err);
    });

  // ****************************************************************
  // ************************* getTrusted ***************************
  // ****************************************************************

  await getTrusted(locale)
    .then((trustedData) => {
      props.trusted = {
        title: trustedData.data.data.attributes.title,
        description: trustedData.data.data.attributes.description,
        partner: trustedData.data.data.attributes.partner,
      };
    })
    .catch((err) => {
      console.error("getTrusted error: ", err);
    });

  // ****************************************************************
  // ************************* getProduct ***************************
  // ****************************************************************

  await getProduct(locale)
    .then((productData) => {
      props.product = {
        title: productData.data.data.attributes.title,
        description: productData.data.data.attributes.description,
        productName: productData.data.data.attributes.productName,
        background:
          productData.data.data?.attributes?.background?.data?.attributes.url ||
          "/uploads/default_008_9fb01dedb6.webp",
      };
    })
    .catch((err) => {
      console.error("getProduct error: ", err);
    });

  // ****************************************************************
  // ************************* getBlogs ***************************
  // ****************************************************************

  await getBlogs(locale, 3)
    .then((blogsData) => {
      const blogsCard = blogsData.data.data.map((res) => ({
        id: res.id,
        title: res.attributes.title,
        cover:
          res.attributes.cover?.data?.attributes?.formats?.thumbnail?.url ||
          "/uploads/default_010_1ae5cc0493.webp",
        contentPreview: res.attributes.contentPreview,
        slug: res.attributes.slug,
        tag: res.attributes.tag,
        publishedAt: res.attributes.publishedAt,
      }));
      // if (props.blog) {
      //   props.blog.blogCard = blogsCard;
      // }
      props.blog = {
        blogCard: blogsCard,
        viewMore: "View More Articles",
        title: "Blogs &amp; News",
      };
    })
    .catch((err) => {
      console.error("getBlogs error: ", err);
    });

  // ****************************************************************
  // ************************* getBlogConfig ************************
  // ****************************************************************

  await getBlogConfig(locale)
    .then((blogConfig) => {
      if (props.blog) {
        props.blog.viewMore = blogConfig.data.data.attributes.viewMore;
        props.blog.title = blogConfig.data.data.attributes.title;
      }
    })
    .catch((err) => {
      console.error("getBlogConfig error: ", err);
    });

  // ****************************************************************
  // ************************* getBlogConfig ************************
  // ****************************************************************

  await getFaq(locale)
    .then((faqData) => {
      props.faq = {
        title: faqData.data.data.attributes.title,
        description: faqData.data.data.attributes.description,
        faq: faqData.data.data.attributes.faq,
      };
    })
    .catch((err) => {
      console.error("getFaq error: ", err);
    });

  //social media
  await getSocial()
    .then((socialData) => {
      props.social = {
        facebook: socialData.data.data.attributes.facebookUrl,
        instagram: socialData.data.data.attributes.instagramUrl,
        twitter: socialData.data.data.attributes.twitterUrl,
        line: socialData.data.data.attributes.lineUrl,
      };
    })
    .catch((err) => {
      console.error("getSocial error: ", err);
    });

  // By returning { props: { posts } }, the Blog component
  // will receive `posts` as a prop at build time
  return {
    props: {
      logo: props.logo,
      header: props.header,
      guarantee: props.guarantee,
      faq: props.faq,
      trusted: props.trusted,
      product: props.product,
      blog: props.blog,
      social: props.social,
    },
  };
}

export default withTranslation()(Home);
