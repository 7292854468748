import React from "react";
import { PartnerType } from "../../utils/requests/rayongboard";
import NewLineBreak from "../NewLineBreak/NewLineBreak";
import PartnersImg from "../OtherAssets/Partner/PartnersImg";

export type TrustedProps = {
  title: string;
  description: string;
  partner: PartnerType[];
};

const Trusted = ({ title, description, partner }: TrustedProps) => {
  
  return (
    <>
      <section className="py-16 lg:py-20" id="trusted-section">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
              <div className="max-w-md">
                <h1 className="mb-6 text-5xl font-bold font-heading">
                  {title}
                </h1>
                <p className="text-gray-500 leading-loose">
                  <NewLineBreak text={description} />
                </p>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4">
              <div className="flex flex-wrap -m-4">
                {partner?.map((res, index) => {
                  if (res?.image?.data) {
                    return (
                      <PartnersImg
                        key={`PartnersImg-${index}`}
                        partner={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${res.image?.data?.attributes?.url}`}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trusted;
