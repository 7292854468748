import Image from "next/image";
import Link from "next/link";
import React from "react";
import { ImageStrapiType } from "../../../utils/requests/rayongboard/defaultStrapi";
import { format } from "date-fns";
export type BlogCardProps = {
  id: number;
  title: string;
  cover?: string | null;
  contentPreview: string;
  slug: string;
  tag: string;
  publishedAt: string;
  viewMore?: string;
};

const BlogCard = ({
  id,
  title,
  cover,
  contentPreview,
  slug,
  tag,
  publishedAt,
  viewMore,
}: BlogCardProps) => {
  return (
    <>
      <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 relative">
        <div className="p-6 bg-primary-50 rounded-lg h-full ">
          <div className="relative h-48 mb-4">
            <span className="absolute top-0 right-0 mt-4 mr-4 text-xs px-2 py-1 bg-primary-50 rounded uppercase text-gray-500 font-semibold">
              {tag}
            </span>
            <Link href={`/blogs/${slug}-${id}`}>
              <Image
                className="w-full h-full object-cover rounded-lg"
                src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${cover}`}
                alt={`${title}-image`}
                width={300}
                height={192}
              />
            </Link>
          </div>
          <span className="inline-block text-xs font-bold text-gray-500">
            {format(new Date(publishedAt), "dd MMM yyyy hh:ss")}
          </span>
          <Link
            className="flex text-ellipsis overflow-hidden line-clamp-2 items-center text-lg font-bold text-gray-700 hover:text-gray-900"
            href={`/blogs/${slug}-${id}`}
          >
            <h1 className="my-2 text-xl font-extrabold font-heading">
              {title}
            </h1>
          </Link>
          <Link className=" hover:text-gray-900" href={`/blogs/${slug}-${id}`}>
            <p className="mb-20 text-ellipsis overflow-hidden line-clamp-2 text-md text-gray-500 leading-loose ">
              {contentPreview}
            </p>
          </Link>
          <div className="absolute bottom-4">
            <Link
              className="flex items-center text-md font-bold text-gray-700 hover:text-gray-900"
              href={`/blogs/${slug}-${id}`}
            >
              <p>{viewMore || "View More Articles"}</p>

              <svg
                className="ml-1 w-5 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
              {/* <p className="opacity-0">{title}</p> */}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
