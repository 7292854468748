import Image from "next/image";
import React, { useState } from "react";

export type ModalImageProps = {
  img?: string;
  logo?: string;
  name?: string;
  onClose?: () => void;
};

const ModalImage = ({ img, logo, name, onClose }: ModalImageProps) => {
  return (
    <>
      <div
        id="modal"
        className=" fixed top-0 left-0 z-50 w-screen h-screen bg-black/70 flex justify-center items-center"
      >
        {/* The close button */}
        <a
          className="fixed z-90 top-2 lg:right-10 right-4 text-white text-5xl font-bold"
          href="javascript:void(0)"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          ×
        </a>
        {/* A big image will be displayed here */}
        {img ? (
          <Image
            id="modal-img"
            src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${img}`}
            className="px-4 md:px-0"
            width={500}
            height={600}
            alt={`${name}-logo`}
          />
        ) : (
          <Image
            id="modal-img"
            src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${logo}`}
            className="px-4 md:px-0"
            width={500}
            height={600}
            alt={`${name}-logo`}
          />
          // <p className="text-white">Image is not found</p>
        )}
      </div>
    </>
  );
};

export default ModalImage;
