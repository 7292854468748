import React from "react";

type NewLineBreakProps = {
  text: string;
};

const NewLineBreak = ({ text }: NewLineBreakProps) => {
    const newLine = "\\n"
  return (
    <>
      {text?.split(newLine).map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      })}
    </>
  );
};

export default NewLineBreak;
