import React from "react";

type ProductCardProps = {
  title: string;
};

const ProductCard = ({ title }: ProductCardProps) => {
  return (
    <>
      <div className="flex justify-start items-center py-4 ">
        <div className="mr-4 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="CurrentColor"
            className="w-12 h-12"
          >
            <path d="m10 17 5-5-5-5v10z" data-path="0.0.2.1.0.0.0" />
          </svg>
        </div>
        <div className="max-w-sm">
          <h2 className="text-lg md:text-xl leading-loose text-gray-600">
            {title}
          </h2>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
