import React, { useState, useEffect } from "react";
import { FaqType } from "../../utils/requests/rayongboard";
import FaqButton from "../Buttons/FaqButton/FaqButton";
import NewLineBreak from "../NewLineBreak/NewLineBreak";

export type FaqProps = {
  title: string;
  description: string;
  faq: FaqType[];
};

const FAQ = ({ title, description, faq }: FaqProps) => {
  const [toggle, setToggle] = useState(1);
  const handleFaqToggle = (id: number) => {
    setToggle(id);
  };
  return (
    <>
      <section className="py-16 lg:py-20" id="faq-section">
        <div className="container px-4 mx-auto">
          <div className="max-w-2xl mx-auto text-center mb-12">
            <h1 className="mt-2 mb-4 text-4xl lg:text-5xl font-bold font-heading">
              {title}
            </h1>
            <p className={`text-lg text-gray-500 leading-loose`}>
              <NewLineBreak text={description} />
            </p>
          </div>

          <div className="max-w-4xl mx-auto">
            <ul className="space-y-4">
              {faq?.map((res, index) => {
                return (
                  <FaqButton
                    title={res.title}
                    content={res.description}
                    hidden={toggle !== index}
                    onClick={() => handleFaqToggle(index)}
                    key={`FaqButton-${index}`}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
