import Image from "next/image";
import React from "react";

type PartnerImgProps = {
  partner: string;
};

const PartnersImg = ({ partner }: PartnerImgProps) => {
  return (
    <>
      <div className="w-1/2 md:w-1/3  p-4">
        <div className="py-4 lg:py-8">
          <Image
            className=" mx-auto"
            src={partner}
            alt="partner-image"
            width={100}
            height={24}
          />
        </div>
      </div>
    </>
  );
};

export default PartnersImg;
